<template>
  <batch-dialog
    :visible="visible"
    @close="closeAction"
    @cancel="cancelAction"
    @onOk="onOk"
    @onDialogOpened="onDialogOpened"
    :record-operation="operation"
    :record-list="recordList"
    :is-selected-record="isSelectedRecord"
    :batch-operation-status="batchOperationStatus"
    :alert="internalAlert"
    :progress="progressText"
  >
    <template v-slot:moreContext>
      <v-divider class="mt-3 mb-2"></v-divider>

      <!-- Print Annotation/Redaction options -->
      <print-annotation-option
        :print-option="printOption"
        :manage-annotations="isAllowedAnnotate"
        :manage-redactions="isAllowedRedact"
        v-if="visibleAnnotationOption"
      >
      </print-annotation-option>
    </template>
  </batch-dialog>
</template>

<script>
// services
import {
  isAllowedOperation,
  IsCheckedOutForDigitalSigningByPrinciple,
  isCompoundDocument,
  localFileFirstOrDefaultExtension
} from "@/services/record/recordService";

// model
import {
  batchRecordStatus,
  findRecordOperation,
  recordOperation
} from "@/model/record/recordModel";
import { fieldDownloadType } from "@/model/document/documentModel";
import {
  downloadFileMode,
  isAnnotateFileTypeExtension,
  isPdfExtension
} from "@/model/record/fileModel";
import { actionResultType } from "@/model/action/actionModel";

// mixins
import { annotationOptionMixin } from "@/mixins/shared/documents/annotationOptionMixin";
import { downloadableMixin } from "@/mixins/shared/downloadable/downloadableMixin";
import { progressAbleMixin } from "@/mixins/shared/progressAble/progressAbleMixin";
import { recordBannerAble } from "@/mixins/shared/bannerAble/recordBannerAble";
import { recordIconMixin } from "@/mixins/shared/record/recordIconMixin";
import { batchDialogMixin } from "@/mixins/shared/batch/batchDialogMixin";
import { alertableMixin } from "@/mixins/shared/alertable/alertableMixin";

export default {
  name: "BatchPrintRecordsDialog",
  components: {
    BatchDialog: () => import("@/components/shared/core/dialogs/BatchDialog")
  },
  mixins: [
    annotationOptionMixin,
    downloadableMixin,
    progressAbleMixin,
    recordBannerAble,
    recordIconMixin,
    batchDialogMixin,
    alertableMixin
  ],
  data() {
    return {
      isDownloadAsPdf: false,
      downloadFileMode: downloadFileMode,
      isSelectedRecord: null,
      isCanceled: false,
      batchOperationStatus: []
    };
  },
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    recordList: undefined,
    downloadMode: {
      type: Number,
      default: downloadFileMode.default
    }
  },
  computed: {
    /**
     * print Operation
     * @return {{name: string, icon: string, label: string}} download Operation
     */
    operation() {
      return findRecordOperation(recordOperation.Print);
    },
    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operation?.label}`;
    },

    /**
     * determines whether current user is Allowed to Annotate current image
     * @return {Boolean|boolean} return true if current user is allowed to Annotate current image
     */
    isAllowedAnnotate() {
      return this.isAllowedOperation(recordOperation.RunImageAnnotation);
    },

    /**
     * determines whether current user is Allowed to Redact current image
     * @return {Boolean|boolean} return true if current user is allowed to Redact current image
     */
    isAllowedRedact() {
      return this.isAllowedOperation(recordOperation.RunImageRedaction);
    },

    /**
     * Is WIP File
     * @return {boolean}
     */
    isWip() {
      return this.downloadMode === downloadFileMode.wip;
    },

    /**
     * visible Annotation Option
     * @return {boolean}
     */
    visibleAnnotationOption() {
      if (this.isWip) {
        return true;
      }

      if (this.isPdf) {
        return false;
      }

      for (const record of this.recordList) {
        if (!isAnnotateFileTypeExtension(record?.extension)) {
          return false;
        }
      }
      return true;
    },

    /**
     * Check if any of the records in the list is a PDF. If any record is a PDF, no need to show download as PDF switch.
     * @return {boolean}
     */
    isPdf() {
      if (this.isWip) {
        // If isWip is true, check each record's extension
        return this.recordList.some(record =>
          isPdfExtension(localFileFirstOrDefaultExtension(record))
        );
      } else {
        // If isWip is false, check each record's extension or checkOutExtension based on other conditions
        return this.recordList.some(record => {
          if (
            IsCheckedOutForDigitalSigningByPrinciple(record, this.principal)
          ) {
            return isPdfExtension(record?.checkOutExtension);
          } else {
            return isPdfExtension(record?.extension);
          }
        });
      }
    },

    /**
     * Check if record is Compound Document
     * @return {boolean} true if provided record is Compound document
     */
    isCompound() {
      for (const record of this.recordList) {
        if (!isCompoundDocument(record)) {
          return false;
        }
      }
      return true;
    },

    /**
     * Is View File Mode (File History)
     * @return {boolean}
     */
    isViewFileMode() {
      return this.downloadMode === downloadFileMode.view;
    }
  },
  methods: {
    /**
     * Print Progress Text
     * @param record
     * @param index
     * @return {string}
     */
    printProgressText(record, index) {
      return `Printing Record ${record.name} - ${index} of ${this.recordList.length}  `;
    },

    async onOk() {
      try {
        for (let i = 0; i < this.recordList.length; i++) {
          if (!this.isCanceled) {
            const record = this.recordList[i];
            this.isSelectedRecord = record;
            this.clearAlert();

            this.progressText = this.printProgressText(record, i + 1);
            const printFileOption = {
              burnAnnotations: this.visibleAnnotationOption
                ? this.printOption?.burnAnnotations ?? false
                : false,
              burnRedaction: this.visibleAnnotationOption
                ? this.printOption?.burnRedaction ?? false
                : false
            };

            /**
             * TODO: use printFileOption sa an arg in: this.onPrintFile()
             */
            const result = await this.onPrintFile(
              record,
              fieldDownloadType.asPdf,
              printFileOption,
              this.downloadMode
            );

            if (result?.type === actionResultType.success) {
              this.setStatus(record.id, batchRecordStatus.Completed, "Success");
              //await this.autoCloseDialog(result.message);
            } else if (result?.type === actionResultType.abort) {
              this.setStatus(record.id, batchRecordStatus.Canceled, "Canceled");
              //this.closeDialog();
            } else {
              this.setStatus(
                record.id,
                batchRecordStatus.Failed,
                result.message
              );
              this.internalAlert = this.createAlert(
                this.alertTypeName.error,
                this.formatAlertError(this.action, result.message),
                false
              );
            }
          }
        }
        this.internalAlert = this.createAlertSuccess(
          `${this.action} Operation Completed`,
          false
        );
      } catch (e) {
        this.closeProgress();
        this.isCanceled = true;

        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      } finally {
        this.closeProgress();
        this.isCanceled = false;
        //this.closeDialog();
        this.isSelectedRecord = undefined;
      }
    },

    /**
     * is Allowed Operation
     * @param operation operation name
     * @return {Boolean|boolean} true if Operation is Allowed
     */
    isAllowedOperation(operation) {
      for (const record of this.recordList) {
        if (!isAllowedOperation(record, operation)) {
          return false;
        }
      }
      return true;
    }
  }
};
</script>
